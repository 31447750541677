<template>
    <div>
        <b-form-group
            label-cols-md="3"
            :label="$t('pages.content.downloadManagement.form.files.label')"
        >
            <b-input-group>
                <b-form-file
                    multiple
                    v-model="selectedFiles"
                    :browse-text="$t('pages.content.downloadManagement.form.files.browseText')"
                    :placeholder="$t('pages.content.downloadManagement.form.files.placeholder')"
                ></b-form-file>
                <b-input-group-append>
                    <b-button variant="orange" @click="addFiles()">{{ $t('buttons.add') }}</b-button>
                </b-input-group-append>
            </b-input-group>
        </b-form-group>
        <b-table
            small
            striped
            :empty-text="$t('generally.tables.emptyText')"
            :empty-filtered-text="$t('generally.tables.emptyFilteredText')"
            show-empty
            :items="files"
            :fields="fields"
            :filter="/^(new|uploaded)$/"
            :filter-included-fields="['state']"
        >
            <template #cell(state)="row">
                <font-awesome-icon :icon="(row.item.state && row.item.state === 'new') ? 'upload' : 'check-circle'" :class="(row.item.state && row.item.state === 'new') ? 'text-orange' : 'text-success'"/>
            </template>
            <template #cell(fileIcon)="row">
                <font-awesome-icon :icon="getIcon(row.item.type)" style="font-size: 20px"/>
            </template>
            <template #cell(name)="row">
                {{ row.item.name }}
            </template>
            <template #cell(size)="row">
                {{ bytesToSize(row.item.size) }}
            </template>
            <template #cell(type)="row">
                <span :title="row.item.type">{{ row.item.type.substring(0, row.item.type.length > 28 ? 28 : row.item.type.length) }}<span v-if="row.item.type.length > 28">...</span></span>
            </template>
            <template #cell(label)="row">
                <b-input-group size="sm">
                    <b-form-input size="sm" list="predefined" v-model="files[files.indexOf(row.item)].label" @click="update" @keyup="update"></b-form-input>
                    <b-input-group-append>
                        <b-button @click="removeFile(row.item)" size="sm" variant="danger">
                            <font-awesome-icon icon="trash-alt"/>
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </template>
        </b-table>

        <datalist id="predefined">
            <option value="Windows x64"/>
            <option value="Windows x86"/>
            <option value="Windows 10 x64"/>
            <option value="Windows 10 x86"/>
            <option value="Windows 8 x64"/>
            <option value="Windows 8 x86"/>
            <option value="Windows 7 x64"/>
            <option value="Windows 7 x86"/>
            <option value="Windows 10"/>
            <option value="Windows 11"/>
            <option value="Linux x64"/>
            <option value="Linux x86"/>
            <option value="Mac OS"/>
            <option value="Mac OS X"/>
            <option value="Android"/>
            <option value="iOS"/>
        </datalist>
    </div>
</template>

<script>
import fileService from "../../services/fileService";
import {getClassNameForMimeType} from "../../services/font-awesome-filetypes";

export default {
    name: "upload-element",
    props: ['value'],
    data: () => ({
        selectedFiles: [],
        listFiles: [],
        fields: [],
        upload: false
    }),
    computed: {
        files() {
            return this.value ? this.value : [];
        }
    },
    watch:{
        files(newValue){
            console.log(this.value);
            this.listFiles = newValue;
        }
    },
    mounted() {
        this.fields = [
            {key: "state", label: '', tdClass: 'align-middle text-center'},
            {key: "fileIcon", label: '', tdClass: 'align-middle text-center'},
            {key: "name", label: this.$t('pages.content.downloadManagement.form.files.table.name'), tdClass: 'align-middle'},
            {key: "size", label: this.$t('pages.content.downloadManagement.form.files.table.size'), tdClass: 'align-middle'},
            {key: "type", label: this.$t('pages.content.downloadManagement.form.files.table.type'), tdClass: 'align-middle'},
            {key: "label", label: this.$t('pages.content.downloadManagement.form.files.table.label'), tdClass: 'align-middle'}
        ];
    },
    methods: {
        update() {
            this.$emit('input', this.listFiles);
        },
        addFiles() {
            this.selectedFiles = this.selectedFiles.filter(file => !this.listFiles.some(f => file.name === f.name && f.state !== 'delete'));
            this.selectedFiles = this.selectedFiles.map(file => {
                file.label = '';
                file.state = 'new';
                return file;
            });
            this.listFiles = [...this.files, ...this.selectedFiles];
            this.selectedFiles = [];
            this.update();
        },
        removeFile(item) {
            let del = -1;
            this.listFiles.map((file, index) => {
                if(file.name === item.name && file.state === 'uploaded'){
                    file.state = 'delete'
                } else if(file.name === item.name && file.state !== 'uploaded') {
                    del = index;
                }
                return file;
            })
            if(del !== -1){
                this.listFiles.splice(del, 1);
            }
            console.log(item.name, del, this.listFiles);
            this.update();
        },
        getIcon(type) {
            return getClassNameForMimeType(type);
        },
        bytesToSize(bytes) {
            return fileService.bytesToSize(bytes);
        }
    }
};
</script>

<style scoped>

</style>
